<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center align-items-center mb-2"
        >
          <h1><feather-icon
            class="mr-2"
            icon="CalendarIcon"
            size="25"
          />Calendario Ferie Utenti</h1>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="text-center"
        >
          <label>Reparto</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="departmentFilter"
            :options="departmentOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => departmentFilter = val"
          />
        </b-col>
        <b-col
          v-if="subDepartmentOptions && subDepartmentOptions.length"
          cols="12"
          md="6"
          class="text-center"
        >
          <label>Sottoreparto</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="subDepartmentFilter"
            :options="subDepartmentOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => subDepartmentFilter = val"
          />
        </b-col>
        <b-col
          v-if="departmentFilter"
          cols="12"
          md="6"
          class="text-center"
        >
          <label>Utente</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userFilter"
            :options="userOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => userFilter = val"
          />
        </b-col>
      </b-row>
    </b-card>
    <div class="appCalendar">
      <div class="app-calendar appCalendar overflow-hidden border">
        <div class="row no-gutters">
          <!-- Sidebar -->
          <div
            class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
            :class="{ show: isCalendarOverlaySidebarActive }"
          >
            <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
          </div>

          <!-- Calendar -->
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <full-calendar
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar"
                />
              </div>
            </div>
          </div>

          <!-- Sidebar Overlay -->
          <div
            class="body-content-overlay"
            :class="{ show: isCalendarOverlaySidebarActive }"
            @click="isCalendarOverlaySidebarActive = false"
          />
          <calendar-event-handler
            v-model="isEventHandlerSidebarActive"
            :event="event"
            :clear-event-data="clearEventData"
            @remove-event="removeEvent"
            @add-event="addEvent"
            @update-event="updateEvent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import userStoreModule from '@/views/apps/user/userStoreModule'
import departmentsStoreModule from '@/views/apps/departments/departmentsStoreModule'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    FullCalendar, // make the <FullCalendar> tag availabl
    CalendarSidebar,
    CalendarEventHandler,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      userOptions,
      departmentOptions,
      userFilter,
      departmentFilter,
      subDepartmentFilter,
      subDepartmentOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      userOptions,
      departmentOptions,
      userFilter,
      departmentFilter,
      subDepartmentFilter,
      subDepartmentOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
  // watch: {
  //   departmentFilter() {
  //     this.userFilter = null
  //   },
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';
</style>
